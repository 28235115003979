const Statuses: string[] = [
  'Developer',
  'Junior Developer',
  'Senior Developer',
  'Manager',
  'Student or Learning',
  'Instructor or Teacher',
  'Intern',
  'Other',
];

export default Statuses;
